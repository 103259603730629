import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';

//  linie pentru a utiliza createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Încapsulează aplicația  în cadrul createRoot
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);