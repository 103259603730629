import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      // Sortați abouts după data de creare în ordine descrescătoare
      const sortedAbouts = data.sort((a, b) => new Date(b._createdAt) - new Date(a._createdAt));
      setAbouts(sortedAbouts);
    });
  }, []);

  return (
    <>
      <h2 className="head-text"><span>un site bine realizat</span> <br /> înseamnă <span>o afacere de succes</span></h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img
              src={urlFor(about.imgUrl)
                .width(400)
                .height(300)
                .quality(80)
                .url()}
              alt={about.alt || about.title}
            />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'despre',
  'app__whitebg',
);
